.work {
    padding-top: 4rem;
    padding-bottom: 13px;
    margin-right: 3rem;
    margin-left: 3rem;
    position: relative;
    background-image: url("../../assets/images/image14.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 5rem;
    margin-bottom: 7rem;
    border-radius: 12px;
}

.overlay {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    opacity: 1;
    background: #000;
    border-radius: 12px;
}
.work-heading {
    text-align: center;
    padding-bottom: 2rem;
}

.work-heading h2 {
    position: relative;
    font-size: 2.2rem;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    padding-top: 2rem;
    color: rgba(0, 0, 0, 0.8);
    color: white;
}
.work-heading span {
    display: inline-block;
    margin-bottom: 0;
    color: #b09639;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: relative;
    font-family: "Poppins", sans-serif;
}
.work-heading span::before {
    position: absolute;
    top: 50%;
    right: -60px;
    content: "";
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50px;
    height: 1px;
    background: #fdae5c;
}
.work-heading span::after {
    position: absolute;
    top: 50%;
    left: -60px;
    content: "";
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50px;
    height: 1px;
    background: #fdae5c;
}
.work-row {
    position: relative;
    padding-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-left: 7rem;

}
/*.column1 {*/
/*    width: 50%;*/
/*}*/
.work-card {
    padding-bottom: 4rem;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 50%;
}

.work-column {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.number {
    width: 90px;
    height: 90px;
    background: #b09639;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.number span {
    color: #fff;
    font-size: 30px;
}
.work-content h3 {
    padding-top: 1rem;
    color: #b09639;
    padding-bottom: 1rem;
    font-size: 24px;
    font-weight: 500;
    font-family: "Poppins", "sans-serif";

}
.work-content p {
    font-size: 15px;
    font-family: "Poppins", "sans-serif";
    font-weight: 400;
    color: white;
    line-height: 1.8;
}
@media screen and (max-width: 1115px) {
    .work-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }
    .work-card {
        max-width: 100%;
    }
}
@media screen and (max-width: 780px) {
    .work {
        margin-right: 1rem;
        margin-left: 1rem;
    }
    .work-row {
        padding: 1rem 1rem 2rem 1rem;
        margin: 0;

    }
    .overlay {
        width: 100%;
        opacity: 0.5;
    }
    .work-heading {
        width: 100%;
    }
    .work-heading h2 {
        font-size: 1.7em;
    }
    .column1 {
        width: 100%;
    }
    .work-card {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
}