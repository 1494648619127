.all-property-container{
    margin-top: 2em;
    position: relative;
    padding-bottom: 1rem;
    display: grid;
    place-items: center;
}

.properties-container{
    padding-top: 2.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    overflow: hidden;
}
.single-property {
    width: 300px;
    border-radius: 1rem;
    box-shadow: 0 5px 15px rgba(0 0 0 / 5%);
    margin-bottom: 30px;
    overflow: hidden;
}
ul li{
    text-decoration: none;
 }


.action-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 2rem 0.5rem 2rem;
}
.action-icon{
    padding: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}
li {
    list-style: none;
}

@media screen and (max-width: 1200px) {
    .properties-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .create-form-hr{
        width: 40em;
    }
}

@media screen and (max-width: 890px) {
    .properties-container {
        grid-template-columns: 1fr;
    }
    .single-property {
        width: 350px;
        overflow: hidden;
    }
    .column {
        padding-bottom: 0.5rem;
    }
    .property-thumb img {
        width: 380px;
    }
    .property-content {
        padding: 15px;
    }
    section.property{
        place-items: center;
    }
    .create-form-hr{
        width: 20em;
    }
}

@media screen and (max-width: 480px) {
    .column {
        margin: 0;
        padding: 0;
    }
    .create-form-hr{
        width: 20em;
    }
    .single-property {
        width: calc(100% - 2rem);
        padding: 0;
        margin: 1rem;
    }
    .property-thumb img {
        width: 100%;
    }
    .property {
        padding-top: 2rem;
        font-size: 18px;
        text-align: center;
    }
}


