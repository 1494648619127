.about-container {
    margin-top: 7em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-container h2 {
    font-family: "Poppins", "sans-serif";
    color: #b09639;
    font-weight: 800;
    font-size: 30px;
    margin-top: 2em;
}

.sxm-pic {
    margin-top: 1em;
    border-radius: 5px;
    height: 30em;
    width: 30em;
}
.text-container {
    margin-top: 4em;
    max-width: 60%;
    text-align: justify;
    font-family: "Merriweather", Serif, "Times New Roman";
}
.text-container p {
    font-size: 16px;
    margin-top: 1em;
}

@media screen and (max-width: 683px) {
    .sxm-pic {
        width: 20em;
        height: 20em;
    }

    .text-container {
        max-width: 78%;
    }
}
@media screen and (max-width: 433px) {
    .text-container {
        font-size: 14px;
    }
    .about-container {
        margin-top: 2rem;
    }
}
