.contact-detail-section {
    align-items: center;
}
.contact-form{
    box-shadow: none;
}

.formWord {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1em;
}
label {
    font-family: "Poppins", "sans-serif";
    font-weight: bolder;
}
.input-field {
    display: flex;
    gap: 2em;
}
.label-wrapper {
    display: flex;
    flex-direction: column;
}
.label-name {
    padding-left: 10px;
}

.input-100 {
    padding: 1rem;
    margin: 8px;
    width: 16em;
    border: #b09639 solid 2px;
    border-radius: 8px;
    background: #e4e4e4;
    z-index: 1;
    font-family: "Poppins", "sans-serif";
    font-size: 15px;

}
.input-message {
    height: 300px;
    width: 600px;
    border-radius: 12px;
    padding: 2rem;
    font-size: 15px;
}
.contact-styling {
    font-family: "Poppins", "sans-serif";
    font-size: 14px;
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-content: center;
}
.contact-styling a{
    text-decoration: underline;
    color: blue;
}
.info-text-styling{
    margin-left: 10em;
    margin-right: 10em;
}
.header-form {
    padding-top: 10em;
    text-align: center;
}
.span-h2 {
    color: #b09639;
}

.btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.btn-wrapper button {
    padding: 0.6rem 0.9em;
    background-color: #9b8742;
    color: white;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 1rem;
}
.form-btn:active {
    background: gold;
    color: black;
}

@media screen and (max-width: 646px) {
    .input-field {
        display: grid;
       gap: 0.6em
    }

    .input-100 {
        width: calc(80vw - 2rem);
    }
    .btn-wrapper button {
        margin-right: 2rem;
    }
    .input-message {
        padding: 1rem;
    }
}
