.slider-wrapper {
    margin-right: 5em;
    margin-left: 5em;
    padding: 0em;
}

.slick-prev {
    left: -2% !important;
    z-index: 1;
    background-color: #b09639 !important;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.slick-next {
    z-index: 1;
    background-color: #b09639 !important;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

h2 {
    text-align: center;
    font-size: 35px;
    font-family: "Poppins", "sans-serif";
    padding-bottom: 0.5em;
}
hr {
    margin-bottom: 1em;
}

.slick-list {
    margin: 0 -5px;
}

.item {
    margin: 0 1rem;
}