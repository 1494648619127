.uploader-card {
    width: 550px;
    height: auto;
    padding: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    border-radius:  5px;
    overflow: hidden;
    background: dimgrey;
    align-items: center;
    align-content: center;
    margin-top: 1.5em;
}

form .upload-form {
    width: 100%;
    height: 160px;
    border-radius: 5px;
    border: 2px dashed #B09639;
    font-size: 0.3rem;
    font-weight: 500;
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    margin-top: 20px;
}

.upload-form .select{
    color: #B09639;
    font-weight: bold;
    margin-left: 7px;
    cursor: pointer;
}

.upload-form input{
    display: none;
}

form.dragover {
    border-style: solid;
    font-size: 1.5rem;
    color: #B09639;
    background: rgba(0, 0, 0, 0.34);
}
.upload-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    height: auto;
    margin-top: 20px;
    max-height: 300px;
    overflow-y: auto;
}
.upload-container .imgs {
    height: 85px;
    width: 85px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;
    margin-bottom: 7px;
    margin-right: 7px;
}
.upload-container .imgs:nth-child(4n) {
    margin-right: 0;
}

.upload-container .imgs img {
    height: 100%;
    width: 100%;
}
.upload-container .imgs span {
    position: absolute;
    top: -4px;
    right: 5px;
    cursor: pointer;
    font-size: 22px;
    color: #fff;
}

.upload-container .imgs span:hover{
    opacity: 0.8;
}