section.property {
    margin-top: 9em;
    position: relative;
    padding-bottom: 1rem;
    display: grid;
    place-items: center;
}

.center h3 {
    font-size: 38px;
    font-weight: 800;
    color: #b09639;
    font-family: "Merriweather", Serif, "Times New Roman";
}

.row h1{
    font-family: "Poppins", "sans-serif";
    font-weight: bold;
    align-items: center;
    align-content: center;
}
.card-footer-list{
    list-style: none;
}
.row {
    padding-top: 2.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
}
.single-property {
    width: 300px;
    border-radius: 1rem;
    box-shadow: 0 5px 15px rgba(0 0 0 / 5%);
    margin-bottom: 30px;
    overflow: hidden;
}

.column {
    padding-left: 1rem;
    padding-right: 1rem;
}
.property-thumb {
    position: relative;
    overflow: hidden;
}
.property-hr{
    width: 70em;
}
.property-tag {
    position: absolute;
    top: 10px;
    left: 15px;
    background: black;
    color: white;
    border-radius: 30px;
    padding: 8px 14px;
    font-size: 15px;
    font-weight: bold;
    z-index: 1;
    font-family: "Poppins", "sans-serif";
}

.property-thumb img {
    width: 350px;
    vertical-align: middle;
    transition: 0.3s;
}
.single-property:hover .property-thumb img {
    transform: scale(1.06);
}
.property-content {
    padding-left: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
}
.property-content h4 {
    margin-bottom: 8px;
    font-size: 16px;
    color: #b09639;
    font-weight: 500;
    font-family: "Poppins", "sans-serif";
}

.location-mark .location {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: dimgrey;
    font-family: "Poppins", "sans-serif";
}

.property-heading {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background: #000;
    opacity: 80%;
    /*position: absolute;*/
    left: 0.1%;
    right: 0;
    top: 90.02%;
    bottom: -1.68%;
    color: #fff;
    font-weight: bolder;
    font-size: 14px;
    font-family: "Poppins", "sans-serif";
}
.property-heading p{
    padding-left: 5px;
}
.price {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background: #b09639;
    opacity: 90%;
    position: absolute;
    border-radius: 5px;
    text-align: center;
    left: 0.1%;
    right: 0;
    top: 90.02%;
    bottom: -1.68%;
    color: black;
    font-size: 16px;
    font-family: "Poppins", "sans-serif";
}
.property-footer {
    border-top: 0.5px solid lightgrey;

}
.property-footer ul {
    display: flex;
    padding: 0;
    text-align: center;
    align-content: center;
    justify-content: space-evenly;
}

.property-footer ul li span {
    font-family: "Poppins", "sans-serif";
    color: #000;
    font-size: 13px;
    font-weight: 400;
    margin-left: 3px;
}
.property-footer ul li .icon-image {
    max-width: 2em;
}
li span::marker{
    font-variant-numeric: normal;
}
.property-image {
    width: 100%;
    height: 250px;
}
.filter-btns{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 18px;
}
button{
    cursor: pointer;
}
.btn-filter{
    padding: 8px 12px;
    background: #9b8742;
    font-size: 16px;
    font-family: "Poppins", "sans-serif";
    border-radius: 12px;
    color: white;

}

.input-box input{
    margin: 6px
}
@media screen and (max-width: 1550px) {
    .row {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media screen and (max-width: 1200px) {
    .row {
        grid-template-columns: 1fr 1fr;
    }
    .property-hr{
        width: 40em;
    }
}

@media screen and (max-width: 890px) {
    .row {
        grid-template-columns: 1fr;
    }
    .single-property {
        width: 350px;
    }
    .column {
        padding-bottom: 0.5rem;
    }
    .property-thumb img {
        width: 380px;
    }
    .property-content {
        padding: 15px;
    }
    section.property{
        place-items: center;
    }
    .property-hr{
        width: 20em;
    }
}

@media screen and (max-width: 480px) {
    .column {
        margin: 0;
        padding: 0;
    }
    .property-hr{
        width: 20em;
    }
    .single-property {
        padding: 0;
        margin: 1rem;
    }
    .property-thumb img {
        width: 100%;
    }
    .property {
        padding-top: 2rem;
        font-size: 18px;
        text-align: center;
    }
    .filter-btns{
        flex-direction: column;
    }
}