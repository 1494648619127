.auth-container {
    background-color: rgba(0, 0, 0, 0.9);
    color: rgba(0, 0, 0, 0.15);
    color: #b09639;
    border-radius: 15px;
    box-shadow: 5px 5px 13px 13px dimgrey;
    margin: auto;
    display: block;
    height: 500px;
    padding: 5px 15px;
    position: fixed;
    top: 50px;
    width: 430px;
    right: 0;
    left: 0;
}
h1 {
    text-align: center;
}
.logo{
    max-width: 120px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.input-container {
    display: flex;
    flex-direction: column;
    margin: 0 2em 0 0;
}
.auth-container__input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.checkbox-container__input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.checkbox-container__input-wrapper label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
}
.auth-btn-group {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}
Input {
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #b09639;
}
.auth-btn-group button {
    width: 210px;
    height: 50px;
    border-radius: 5px;
    outline: none;
    background-color: #b09639;
    font-size: 1rem;
    color: white;
    font-weight: 600;
    border: 2px solid #b09639;
    cursor: pointer;
}
.auth-btn-group button:hover {
    opacity: 0.7;
    transition: 0.2s ease-in-out;
    transform: scale(1.01);
}

.auth-btn-group button:nth-child(1) {
    background-color: #b09639;
    border: none;
    border-radius: 0.4rem;
}
.auth-btn-group button:nth-child(2) {
    color: #b09639;
    border: none;
    border-radius: 0.4rem;
}

.auth-container__input {
    width: calc(100% - 3rem);
    margin: 0;
    padding: 1em;
}
.auth-container__text {
    font-size: 1.1rem;
    font-weight: 400;
    text-align: justify;
    margin: 2rem 1rem 2rem 1rem;
}

@media only screen and (max-width: 768px) {
    .auth-container__input {
        width: 90%;
    }
    .auth-btn-group {
        gap: 0.3rem;
    }
    .date-picker-wrapper {
        width: 90%;
    }
}

@media only screen and (max-width: 480px) {
    .auth-container {
        width: calc(100% - 3rem);
    }
    .auth-container__input {
        width: 100%;
        margin-left: -2rem;
    }
    .checkbox-container__input-wrapper label {
        font-size: 0.8rem;
    }
}