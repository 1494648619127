.footer{
    position: relative;
    padding: 2rem;
    margin-top: 2em;
    background: #000;
    color: #fff;
    text-decoration: none;
}
.text{
    list-style: none;
}
.footer-column .info-property{
    margin-right: 3em;
}

.footer-icon{
    padding: 7px;
}
.footer-container{
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    line-height: 1.5em;
}
.footer-column{
    display: flex;
    align-items: center;
}

.logo-img{
    margin-top: 2em;
    max-width: 200px;

}

.footer-row{
    display: flex;
    justify-content: center;
    gap: 3rem;
}
.column-content ul{
    margin-top: 3rem;
    list-style: none;
}

.column-content .footer-link li{
    margin: 0 30px 0 0;
    color: whitesmoke;
}

.info-property{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    padding-right:12em;

}
.footer-2{
    padding: 2rem 0;
    display: grid;
    place-items: center;
}
button{
    background: none;
    border: none;
}
.link-style{
    margin-top: 3em;
    list-style: none;
    text-decoration: none;

}
.btn-footer{
    margin-top: 3em;

}
a.btn-link {
    text-decoration: none;
    color: white;
    border-radius: 18px;
    font-size: 16px;
    border: transparent;
    background: #B09639;
    padding: 10px 15px;
    margin-top: 3em;


}

@media screen and (max-width: 905px) {
    .footer-container {
        display: flex;
        justify-content: center;
        padding: 3em 2em;

    }

    .footer-row {
        display: flex;
        flex-direction: column;
        gap: 0;
        padding: 0;
    }
    .info-property{
        padding-right: 1em;
    }

    .footer-column {
        margin-top: 2em;
        display: flex;
        flex-direction: column;
        line-height: 1.8em;
    }

    .column-content {
        gap: 0;
    }

    .link-style {
        text-align: center;
        max-width: none;
    }
}
@media screen and (max-width: 494px){
    .footer-2{
        display: flex;
        flex-wrap: wrap;
    }
    .link-style{
        text-align: center;
    }
}