.property-detail-container {
    margin-top: 9rem;
    align-items: center;
}
.carousel .slider-wrapper{
    width: 100%;
}

button.control-arrow.control-prev{
    background: rgba(176, 150, 57, 0.7);
}

.property-detail {
    font-size: 1.2rem;
    font-weight: 400;
    padding: 1rem 2rem;
    background: rgba(176, 150, 57, 0.1);
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 8px;
}

.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}
.images-container img {
    height: 45em;
}

.contact-container button {
    background-color: #9b8742;
    color: white;
    padding: 1rem 2rem;
    text-align: center;
    font-size: 1.5rem;
    font-family: "Poppins", "sans-serif";
    border-radius: 80%;
    margin-bottom: 2rem;
}
.contact-container button:hover {
    background-color: #9b8742;
    opacity: 0.6;
    cursor: pointer;
}

@media screen and (max-width: 1050px) {
    .property-detail-container {
        margin-top: 7.5rem;
    }

    .carousel-text {
        font-size: 1rem;
    }
    .images-container img {
        height: 30em;
    }
}

@media screen and (max-width: 524px) {
    .contact-container button {
        background-color: #9b8742;
        color: white;
        padding: 1rem 1rem;
        text-align: center;
        font-size: 1rem;
        border-radius: 5px;
        margin-bottom: 2rem;
    }
}
@media screen and (max-width: 400px){
    .images-container img {
        height: 400px;
    }
}