.create-form-container{
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    font-family: "Poppins", "sans-serif";
    border-radius: 8px;
    padding-top: 4em;
}
label{
    color: white;
}
h4{
    color: #9b8742;
    font-weight: 800;

}
.create-title{
    font-size: 2.5em;
    color: #9b8742;
}

.create-form-hr{
    width: 100%;
    margin-top: 10px;
}
.description-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin: auto;
}

select{
    padding: 8px;
    border-radius: 12px;
    border: #9b8742 solid 2px;
    font-size: 18px;
}
.wrapper-1, .wrapper-2, .wrapper-3, .wrapper-4, .wrapper-5{
    background-color: dimgrey;
    gap: 5px;
    margin-left: 10em;
    margin-right: 10em;
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);

}
.wrapper-1{
    border-radius: 4px 4px 0 0;
}
.wrapper-3{
    border-radius: 0 0 4px 4px;
}
.wrapper-4{
    border-radius: 4px 4px 0 0;
}

.wrapper-5{
    border-radius: 0 0 4px 4px;

}

.form-container{
    margin: 1em;
}

.create-title{
    font-weight: 600;
    font-family: "Poppins", "sans-serif";
}

.image-description{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    align-content: center;
    margin-left: 4em;
}
div.images-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin: auto;
}

.form-btn{
    background-color: #9b8742;
    font-size: 18px;
    color: white;
    padding: 9px 25px;
    border-radius: 12px;
    margin-top: 4em;
    margin-bottom: 8em;

}
.divider{
    background-color: black;
}

input {
    font-size: 18px;
}
.description-wrapper textarea{
    box-sizing: border-box;
    resize: none;
    font-size: 17px;
    word-break: break-all;
    text-align: start;
    text-transform: full-width;
}
.editor-container{
    background: #fff;
    border-radius: 8px;
    width: 650px;
}

.form-container {
    color: black;
    background-color: #ffffff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);

}
.close-bttn{
    display: flex;
    justify-content: left;
    color: #FFFFFF;
    margin-left: 3em;
    background: black;
    border-radius: 18px;
    padding: 8px;
    border: 1px solid #9b8742;
    transition: 1s ease-in-out;
    letter-spacing: 0.1rem;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}


@media screen and (max-width: 975px){

    .image-description{
        display: flex;
        flex-direction: column;

    }

    .description-wrapper textarea{
        align-content: center;
        align-items: center;
        width: 100%;
        box-sizing: content-box;
    }
    .uploader-card {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2em;
    }

}