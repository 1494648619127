.header {
    display: flex;
    height: 100vh;
    width: 100%;
    z-index: 9;
}
.navbar{
    position: absolute;
    align-content: center;
    display: flex;
    align-items: center;
    z-index: 999;
}

.container h1 {
    margin-top: 1.8em;
    color: white;
    font-size: 6rem;
    position: relative;
    font-family: "Poppins", Serif, "Times New Roman";
    letter-spacing: 1px;
    line-height: 1.3;
    margin-bottom: 10px;
}
.header-btns{
    margin-top: 21rem;
}
.link {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 39%;
    left: 36%;
}
.buy {
    margin-right: 2rem;
    transition: 1s ease-in-out;
    border-radius: 2rem 0 2rem 0;
    background: #b09639;
    color: white;
}
.buy:hover {
    background: rgba(215, 215, 215, 0.8);
    transform: scale(1.2, 1.3);
    color: #b09639;
    -webkit-transform: scale(1.2, 1.3);
    -moz-transform: scale(1.2, 1.3);
}

.rent {
    margin-right: 2rem;
    border-radius: 2rem 0 2rem 0;
    background: rgba(215, 215, 215, 0.8);
    color: #FFFFFF;
}

.buy,
.rent {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-family: "Poppins", "sans-serif";
    font-size: 1.5rem;
    padding: 2rem 4rem;
    letter-spacing: 0.2rem;
    transition: 1s ease-in-out;
    text-transform: uppercase;
    border: 1px solid rgba(255,255,255,.5);
    margin-top: 2em;
}
.rent:hover {
    color: antiquewhite;
    background: #b09639;
    transform: scale(1.2, 1.3);
    -webkit-transform: scale(1.2, 1.3);
    -moz-transform: scale(1.2, 1.3);
}


@media screen and (min-width: 200px) and (max-width: 1050px) {
    .home {
        padding-left: 3rem;
    }

    .container{
        margin: 0;
    }

    .container h1 {
        text-align-all: left;
        text-align: center;
    }
    .buy,
    .rent {
        font-size: 20px;
        text-align-all: center;
    }
    .bEEnfA{
        top: 55% !important;
    }
}
@media screen and (min-width: 355px) and (max-width: 685px) {
    .header-btns{
        display: flex;
        margin-top: 12em;
    }

    .buy,
    .rent {
        font-size: 1.2rem;
        text-align-all: center;
    }
    .home {
        width: 100%;
    }
}
@media screen and (max-width: 685px) {
    .header {
        height: 600px;
    }
    .buy,
    .rent {
        font-size: 1rem;
        text-align-all: center;
        padding: 1rem;
    }
    .container h1 {
        font-size: 2.5rem;
    }

}
@media screen and (max-width: 566px) {
    .buy,
    .rent {
        font-size: 0.7rem;
        text-align-all: center;
    }
}


