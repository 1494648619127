.admin-header-container {
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    padding: 1.5rem 2rem;
}
.current-user-email {
    text-align: center;
    color: white;
}
.logout-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-top: 0.5rem;
}
.logout-text:hover {
    cursor: pointer;
}
.logout-text{
    background: black;
    border-radius: 18px;
    padding: 8px;
    border: 1px solid #9b8742;
    transition: 1s ease-in-out;
    letter-spacing: 0.1rem;
    padding: 8px;
}
.add-property-btn {
    background: #9b8742;
    border-radius: 18px;
    border: 1px solid rgba(255,255,255,.5);
    transition: 1s ease-in-out;
    letter-spacing: 0.1rem;
    padding: 8px;
}
a{
    text-decoration: none;
}