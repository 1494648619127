.modal-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.popup-modal{
    background-color: rgba(0, 0, 0, 0.9);
    color: rgba(0, 0, 0, 0.15);
    color: #B09639;
    border-radius: 15px;
    box-shadow:  5px 5px 13px 13px dimgrey;
    margin: auto;
    display: block;
    height: 650px;
    padding: 20px;
    position: fixed;
    top: 50px;
    width: 430px;
    right: 0;
    left: 0;
}
.login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 4rem;
}

.login-container input {
    font-size: 18px;
}
.login-form {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

}

.login-form h1 {
    text-align: center;
    color: #9b8742;
}
.login-form input {
    width: 100%;
    height: 50px;
}

.input-container {
    width: 80%;
    margin-left: 2rem;
}
.input-container a {
    color: #9b8742;
}
.login-btn {
    width: 60%;
    padding: 1rem 2rem;
    background-color: #9b8742;
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    border-radius: 0;
    margin: 0;
    border-radius: 15px;
}

.close-btn{
    background: #B09639;
    width: 18%;
    font-size: 18px;
    color: #FFFFFF;
    border-radius: 15px;
}

.login-btn:hover {
    cursor: pointer;
    opacity: 0.8;
}

@media screen and (max-width: 450px) {
    .login-form {
        width: 100%;
        margin: 2rem;
    }
}
