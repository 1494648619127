.popup-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
}
.popup-context {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 50px;
    border-radius: 15px;
}
.request-buttons {
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
}
.request-buttons button {
    padding: 12px;
    border-radius: 8px;
    margin-left: 12px;
    margin-right: 12px;

}