
.property-filter nav{
    display: flex;
    padding: 8px;
    background: #fff;
    border-radius: 8px;
    border: 2px solid #B09639;

    gap: 10px;



}
.property-filter p{
    font-size: 0.9rem;
    margin-right: 10px;
}
.filter-title{
    margin: 0;
}

.property-filter button {
    border: 0;
    font-family: "Poppins", "sans-serif";
    font-weight: bold;
    cursor: pointer;
    padding: 4px;
    border-right: 1px solid darkgrey;
    font-size: 1rem;
}

.property-filter button:last-child{
    border: 0;

}

.property-filter button.active{
    color: #9b8742;
}