.vision-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5rem;
    gap: 3rem;
    margin-top: 3rem;
}
.vision-section .vision-text {
    font-size: 20px;
    font-weight: 400;
    color: black;
    line-height: 32px;
    margin-top: 0;
    padding: 0;
    font-family: "Poppins", "sans-serif";

}

@media only screen and (max-width: 1024px) {
    .vision-section {
        flex-direction: column;
        padding: 1rem;
    }
    .vision-section img {
        width: 100%;
        height: 100%;
    }
    .vision-section .vision-text {
        font-size: 16px;
        font-weight: 300;
    }
}