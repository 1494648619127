nav {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*padding: 18px 5rem 18px 5rem;*/
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.logo{
    max-width: 120px;
    padding-bottom: 10px;
    margin: 0;
}

nav a {
    display: flex;
    align-items: center;
    align-content: center;
}
nav .logo-slogan {
    font-size: 26px;
    text-decoration: none;
    color: white;
    font-family: "Poppins", "sans-serif";

}

nav a {
    text-decoration: none;
    font-size: 1.2rem;
}

.menu {
    display: flex;
}

.menu ul{
    display: flex;
}
.menu ul li{
    list-style: none;
    padding: 20px 25px;
    font-size: 14px;
}

.menu ul li a{
    color: white;
    font-weight: 400;
    text-decoration: none;
    font-family: "Poppins", "sans-serif";
    letter-spacing: 0.5px;
}

.menu ul li a.active{
    color: #b09639;
    font-weight: bold;
}
.nav-header {
    margin: 8px;
    display: inline-flex;
}

.fa-bars {
    display: none;
    font-size: 2rem;
    cursor: pointer;
    color: #b09639;
}
.nav-active {
    position: fixed;
    top: -70px;
    left: 0;
    right: 0;
    z-index: 990;
    background: black;
    transform: translateY(70px);
    transition: transform 1s ease;
}
@media screen and (max-width: 1050px) {
    nav {
        padding-left: 0.3rem;
        padding-right: 0.5em;
    }

    .btn {
        margin: 0;
        font-size: 8px;
        padding: 0;
    }

    .fa-bars {
        display: flex;
        color: #b09639;
        padding-right: 2em;
        margin-top: 0.4rem;
    }
    .menu {
        display: block;
        z-index: 9999 !important;
        position: absolute;
        left: 3%;
        top: 70px;
        width: 93%;
        background-color: #181b22;
        transform: translateY(-300px);
        transition: transform 1s ease-in-out, visibility 1s;
        visibility: hidden;
        padding: 1rem;
        border-radius: 0.5rem;
    }

    .mobile-menu {
        visibility: visible;
        transform: translateY(0px);
        transition: transfprm 1s ease-in-out, visibility 1s;
    }
    .menu ul {
        display: block;
    }
    .menu ul li {
        padding: 10px 2rem;
        font-size: 1rem;
        font-weight: bold;
    }

    .menu ul li:hover {
        background: darkgoldenrod;
    }
    .btn {
        margin-right: 2rem;
        border: none;
        background: gold;
        padding: 7px 10px 7px 10px;
    }
}

@media screen and (max-width: 522px) {
    nav {
        display: flex;
        justify-items: flex-start;
        align-items: start;
    }


    .fa-bars {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 350px) {
    nav {
        right: 1rem;
    }

    nav h1 {
        font-size: 1.5rem;
        position: relative;
        left: 10%;
    }

    .fa-bars {
        font-size: 1.4rem;
    }
}